import React, { useEffect, useRef } from "react";
import classNames from "classnames";
import { useMediaQuery } from "react-responsive";

// Assets
import info_steps_mobile from "../../../resources/info_steps_mobile.png";
import info_steps from "../../../resources/info_steps.png";

// Styles
import "./../style.sass";
import "./style.sass";

const ModalInfo = ({ bgColor, toggleInfo, setToggleInfo }) => {
    const modalRef = useRef(null);
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });

    useEffect(() => {
        let callback = (event) => {
            if (!modalRef.current) return;

            if (
                !modalRef.current.contains(event.target) &&
                !event.target.classList.contains("player-option-info")
            ) {
                setToggleInfo((state) => state && !state);
            }
        };

        document.addEventListener("click", callback);
        return () => {
            document.removeEventListener("click", callback);
        };
    }, [setToggleInfo]);

    return (
        <div
            className={classNames("modal-helper", { visible: toggleInfo })}
            ref={modalRef}
        >
            <div className="modal-helper-header">
                <p className="modal-helper-title">Karaoke App</p>
            </div>
            <div className="modal-helper-body">
                <p className="modal-title">Part of Master Thesis <a href="https://drive.google.com/file/d/1-TUVbct1GUy39q_LeUbZ8MQDXwXK532I/view?usp=sharing">Keyword Spotting of Stretched and Elongated Spoken Words</a> - learning better speech representations from Music Data and it happens that the trained model can be used for Karaoke as well.</p>
                <img
                    className="modal-helper-asset"
                    src={isTabletOrMobile ? info_steps_mobile : info_steps}
                    alt="App Info"
                />
            </div>
            <div
                className="modal-helper-footer"
                onClick={() => setToggleInfo(false)}
                style={{ color: bgColor }}
            >
                <div className="modal-helper-action">
                    <p className="modal-helper-action-title">OK!</p>
                </div>
            </div>
        </div>
    );
};

export default ModalInfo;
