import React, { useCallback, useEffect, useState } from "react";

// Utils
import classNames from "classnames";

// Icons
import icon from "../../resources/menu_black.svg";

// Styles
import "./style.sass";

export const useClickableMenu = ({ onMenuCallback, target }) => {
    const [menu, setMenu] = useState(false);
    const onTriggerMenu = useCallback(
        (event) => {
            if (event.target.classList.contains(target) || !menu) {
                return;
            }

            setMenu((menu) => !menu);

            event.stopPropagation();
        },
        [menu, target]
    );

    const onClickMenu = (event) => {
        setMenu((menu) => !menu);
    };

    useEffect(() => {
        window.addEventListener("click", onTriggerMenu);
        return () => {
            window.removeEventListener("click", onTriggerMenu);
        };
    }, [onTriggerMenu]);

    useEffect(() => {
        if (onMenuCallback) {
            onMenuCallback(menu);
        }
    }, [menu, onMenuCallback]);

    return [menu, onClickMenu];
};

export const Menu = (props) => {
    const [menu, callback] = useClickableMenu({
        target: "menu-asset",
        ...props,
    });

    return (
        <div className={classNames("menu menu-asset", { "menu-on": menu })}>
            <img
                className="menu-asset"
                src={icon}
                onClick={callback}
                alt="Menu"
            />
            <div className="menu-container">{props.actions}</div>
        </div>
    );
};
