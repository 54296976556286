import React from "react";
import moment from "moment";

// Assets
import button_previous from "../../resources/button_skip_previous_white.svg";
import button_play from "../../resources/button_play_white.svg";
import button_pause from "../../resources/button_pause_white.svg";
import button_next from "../../resources/button_skip_next_white.svg";
import volume_mute from "../../resources/volume_off.svg";
import volume_down from "../../resources/volume_down.svg";
import volume_up from "../../resources/volume_up.svg";

// Styles
import "./style.sass";

export const PlaybackControls = ({
    playing,
    togglePlaying,
    prevSong,
    nextSong,
}) => {
    return (
        <div className="player-controls">
            <img
                className="player-btn"
                src={button_previous}
                onClick={prevSong}
                alt="Previous"
            />
            {!playing ? (
                <img
                    className="player-btn player-btn-main"
                    src={button_play}
                    onClick={togglePlaying}
                    alt="Play"
                />
            ) : (
                <img
                    className="player-btn player-btn-main"
                    src={button_pause}
                    onClick={togglePlaying}
                    alt="Pause"
                />
            )}
            <img
                className="player-btn"
                src={button_next}
                onClick={nextSong}
                alt="Next"
            />
        </div>
    );
};

export const PlayerSettings = ({ volume, setVolume, muted, toggleMuted }) => {
    const value = volume * 100;
    const style = {
        background: `linear-gradient(to right, #d3d3d3 0%, #d3d3d3 ${value}%, #636363 ${value}%, #636363 100%)`,
    };

    return (
        <React.Fragment>
            <div className="player-volume">
                {volume === 0 || muted ? (
                    <img
                        className="player-btn"
                        src={volume_mute}
                        onClick={toggleMuted}
                        alt="Volume Mute"
                    />
                ) : volume < 0.6 ? (
                    <img
                        className="player-btn"
                        src={volume_down}
                        onClick={toggleMuted}
                        alt="Volume Down"
                    />
                ) : (
                    <img
                        className="player-btn"
                        src={volume_up}
                        onClick={toggleMuted}
                        alt="Volume Up"
                    />
                )}
                <input
                    type="range"
                    min="0"
                    max="1"
                    step="any"
                    className="slider ms-2"
                    value={volume}
                    style={style}
                    onChange={(event) =>
                        setVolume(Number.parseFloat(event.target.value))
                    }
                />
            </div>
        </React.Fragment>
    );
};

export const PlayerSlider = ({ player, progress, isTabletOrMobile }) => {
    let duration = 0;
    if (player.current) {
        duration = player.current.getDuration();
    }

    let lasting = duration - progress.playedSeconds;
    let lastingFormatted = moment.utc(lasting * 1000).format("mm:ss");
    let progressFormatted = moment
        .utc(progress.playedSeconds * 1000)
        .format("mm:ss");

    const leftColor = isTabletOrMobile ? "white" : "#d3d3d3";

    const value = (progress.playedSeconds / duration) * 100;
    const style = {
        background: `linear-gradient(to right, ${leftColor} 0%, ${leftColor} ${value}%, #636363 ${value}%, #636363 100%)`,
    };

    return (
        <React.Fragment>
            <div className="player-slider">
                <span className="player-progress player-progress-left">
                    {progressFormatted}
                </span>
                <input
                    type="range"
                    min="0"
                    max={duration}
                    step="any"
                    className="slider ms-2"
                    style={style}
                    value={progress.playedSeconds}
                    onChange={(event) =>
                        player.current &&
                        player.current.seekTo(
                            Number.parseFloat(event.target.value)
                        )
                    }
                />
                <span className="player-progress player-progress-right">
                    {lastingFormatted}
                </span>
            </div>
        </React.Fragment>
    );
};
