import React from "react";
import ReactDOM from "react-dom";
import App from "./components/main";

// Styles
import "./index.sass";

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById("root")
);
