import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";

// Components
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer";

// Styles
import "./../style.sass";
import "./style.sass";

const ModalDiff = ({
    bgColor,
    toggleDiff,
    setToggleDiff,
    track,
    setIsDisabled,
    setCurrentTrack,
}) => {
    const modalRef = useRef(null);
    const [replaceLyrics, setReplaceLyrics] = useState(false);
    const [lyrics, setLyrics] = useState(track.transcript);

    useEffect(() => {
        setLyrics(track.transcript);
    }, [track]);

    useEffect(() => {
        let callback = (event) => {
            if (!modalRef.current) return;

            if (
                !modalRef.current.contains(event.target) &&
                !event.target.classList.contains("player-option-settings")
            ) {
                setToggleDiff((state) => state && !state);
            }
        };

        document.addEventListener("click", callback);
        return () => {
            document.removeEventListener("click", callback);
        };
    }, [setToggleDiff]);

    if (!track.raw_output) {
        return null;
    }

    const onAlignContents = () => {
        setIsDisabled(true);
        setToggleDiff(false);
        fetch("/finish", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                id: track.id,
                content: lyrics,
            }),
        })
            .then((res) => res.json())
            .then((result) => {
                setCurrentTrack(result);
            })
            .finally(() => {
                setIsDisabled(false);
            });
    };

    const newValue = lyrics.replace(/\s+/g, " ").trim().toLowerCase();

    return (
        <div
            className={classNames("modal-helper modal-diff", {
                visible: toggleDiff,
            })}
            ref={modalRef}
        >
            <div className="modal-helper-header">
                <p className="modal-helper-title">Diff</p>
            </div>
            <div className="modal-helper-body">
                <div className="modal-helper-col">
                    <p className="diff-mismatch-header">
                        Mismatch ratio {track.raw_output.score.toFixed(2)}%
                    </p>
                    <p className="diff-mismatch-hint">
                        Edit the lyrics to improve the final alignment.
                    </p>
                </div>
                <div className="modal-helper-row modal-helper-diff">
                    {replaceLyrics ? (
                        <textarea
                            className="textarea-lyrics"
                            onChange={(event) => setLyrics(event.target.value)}
                        >
                            {lyrics}
                        </textarea>
                    ) : (
                        <ReactDiffViewer
                            oldValue={track.raw_output.raw}
                            newValue={newValue}
                            hideLineNumbers={true}
                            leftTitle={"Model Output"}
                            rightTitle={"Fetched Lyrics Transcript"}
                            compareMethod={DiffMethod.WORDS}
                            splitView={true}
                        />
                    )}
                </div>
                <div
                    className="modal-helper-row diff-toggle"
                    onClick={() => {
                        setReplaceLyrics((state) => !state);
                    }}
                >
                    {replaceLyrics ? "Back" : "Edit Lyrics"}
                </div>
            </div>
            <div className="modal-helper-footer" style={{ color: bgColor }}>
                <div className="modal-helper-action" onClick={onAlignContents}>
                    <p className="modal-helper-action-title">Align</p>
                </div>
                <div
                    className="modal-helper-action"
                    onClick={() => setToggleDiff(false)}
                >
                    <p className="modal-helper-action-title">Close</p>
                </div>
            </div>
        </div>
    );
};

export default ModalDiff;
