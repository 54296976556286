import React, { useState, useEffect, useMemo, useRef } from "react";
import classNames from "classnames";
import { useMediaQuery } from "react-responsive";
import { textEllipsis } from "../../utils";

// Components
import { Menu } from "../menu";

// Assets
import sync_black from "../../resources/sync_black.svg";
import search_black from "../../resources/search_black.svg";
import search_close from "../../resources/close_black.svg";
import library_music from "../../resources/library_music_black.svg";
import settings from "../../resources/settings_black.svg";
import info from "../../resources/info_black.svg";

// Styles
import "./style.sass";

const MAX_TITLE_CHARACTERS = 40;
const LOCK_SEARCH_DELAY = 1000;

export const Search = ({
    demos,
    track,
    setCurrentTrack,
    toggleInfo,
    setToggleInfo,
    isDisabled,
    setIsDisabled,
    toggleDiff,
    setToggleDiff,
}) => {
    const [searchValue, setSearchValue] = useState("");
    const [searchResult, setSearchResult] = useState(null);
    const [searchRequests, setSearchRequests] = useState([]);
    const [searchLock, setSearchLock] = useState({ state: false, query: null });
    const searchRef = useRef(null);
    const [searchIsFocused, setSearchIsFocused] = useState(false);
    const [toggleDemos, setToggleDemos] = useState(false);
    const isLoading = useMemo(() => {
        return searchRequests.length > 0;
    }, [searchRequests]);
    const formatter = useRef(Intl.NumberFormat("en", { notation: "compact" }));
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });

    useEffect(() => {
        if (
            searchValue.length > 0 &&
            !searchLock.state &&
            searchLock.query !== searchValue
        ) {
            setSearchRequests((state) => state.concat(searchValue));
            fetch(`/search?q=${searchValue}`)
                .then((res) => res.json())
                .then(setSearchResult)
                .finally(() => {
                    setSearchRequests((state) =>
                        state.filter((item) => item !== searchValue)
                    );
                });

            setSearchLock({ state: true, query: searchValue });
            setTimeout(() => {
                setSearchLock((state) => ({ ...state, state: false }));
            }, LOCK_SEARCH_DELAY);
        }

        if (searchValue.length === 0) {
            setSearchResult(null);
        }
    }, [searchValue, searchLock]);

    useEffect(() => {
        let callback = (event) => {
            if (!searchRef.current) return;

            if (!searchRef.current.contains(event.target)) {
                setSearchIsFocused(false);
                setToggleDemos(false);
            }
        };

        document.addEventListener("click", callback);
        return () => {
            document.removeEventListener("click", callback);
        };
    }, []);

    useEffect(() => {
        if (toggleDemos) {
            setSearchIsFocused(true);
        }
    }, [toggleDemos]);

    const playerOptions = [
        <div
            key="demos"
            className="player-option"
            onClick={() => {
                setToggleDemos((state) => !state);
            }}
        >
            <img
                className={classNames(
                    "player-option-icon player-option-demos",
                    { active: toggleDemos }
                )}
                src={library_music}
                alt="Demo samples to try out..."
            />
        </div>,
        <div
            key="settings"
            className={classNames("player-option", {
                disabled: !track.raw_output,
            })}
            onClick={() => {
                if (track.raw_output) {
                    setToggleDiff((state) => !state);
                }
            }}
        >
            <img
                className={classNames(
                    "player-option-icon player-option-settings",
                    { active: toggleDiff }
                )}
                src={settings}
                alt="Settings for track..."
            />
        </div>,
        <div
            key="info"
            className="player-option"
            onClick={() => {
                setToggleInfo((state) => !state);
            }}
        >
            <img
                className={classNames("player-option-icon player-option-info", {
                    active: toggleInfo,
                })}
                src={info}
                alt="Info"
            />
        </div>,
    ];

    // Matches the YouTube UX.
    const isSearching = isDisabled;

    return (
        <div
            className={classNames("player-hoverable", {
                active: searchIsFocused,
                disabled: isDisabled,
            })}
            ref={searchRef}
            onFocus={() => setSearchIsFocused(true)}
        >
            <div className="player-search">
                <img
                    className={classNames(
                        "player-search-icon player-search-loading",
                        { hidden: !isSearching }
                    )}
                    src={sync_black}
                    alt="Search Loading"
                />
                <img
                    className={classNames("player-search-icon", {
                        hidden: isSearching,
                    })}
                    src={search_black}
                    alt="Search Icon"
                />
                <input
                    className="player-search-input"
                    type="text"
                    value={searchValue}
                    disabled={isDisabled}
                    onChange={(event) => setSearchValue(event.target.value)}
                    placeholder="Song, YouTube URL..."
                    aria-label="Search"
                />
                {!isDisabled && (
                    <img
                        className={classNames("player-search-clear", {
                            active: searchValue.length > 0,
                        })}
                        src={search_close}
                        onClick={() => setSearchValue("")}
                        alt="Search Clear"
                    />
                )}
            </div>
            <div className="search-options">
                {toggleDemos ? (
                    <div>
                        <div className="search-options-label">
                            <span>Demos</span>
                        </div>
                        {demos.map((track) => (
                            <div
                                key={track.id}
                                className="player-info"
                                onClick={() => setCurrentTrack(track)}
                            >
                                <img
                                    className="track-thumbnail"
                                    src={track.meta.lyrics.header_image_url}
                                    alt="Track thumbnail"
                                />
                                <div className="track-meta">
                                    <p className="track-meta-label track-title">
                                        {track.sample.title}
                                    </p>
                                    <p className="track-meta-label track-artist">
                                        {track.sample.artist}
                                    </p>
                                </div>
                                <span className="track-meta-duration">
                                    {track.meta.audio.duration_string}
                                </span>
                            </div>
                        ))}
                    </div>
                ) : (
                    searchResult && (
                        <div>
                            <div className="search-options-label">
                                <span>
                                    {isLoading
                                        ? "Searching..."
                                        : searchResult.label}
                                </span>
                            </div>
                            {searchResult.items.map((result) => {
                                const resultTitle = textEllipsis(
                                    result.title,
                                    MAX_TITLE_CHARACTERS
                                );

                                let thumbnail = null;
                                if (
                                    result.thumbnails &&
                                    result.thumbnails.length > 0
                                ) {
                                    thumbnail = result.thumbnails[0].url;
                                }

                                const onClickCallback = () => {
                                    setIsDisabled(true);
                                    setSearchIsFocused(false);

                                    const query = new URLSearchParams({
                                        query: searchResult.query,
                                        id: result.id,
                                    });

                                    fetch("/query?" + query)
                                        .then((res) => res.json())
                                        .then((result) => {
                                            setCurrentTrack(result);
                                        })
                                        .finally(() => {
                                            setIsDisabled(false);
                                        });
                                };

                                return (
                                    <div
                                        key={result.id}
                                        className="player-info"
                                        onClick={onClickCallback}
                                    >
                                        <img
                                            className="track-thumbnail"
                                            src={thumbnail}
                                            alt="Track thumbnail"
                                        />
                                        <div className="track-meta">
                                            <p className="track-meta-label track-title">
                                                {resultTitle}
                                            </p>
                                            <div className="track-meta-metrics">
                                                <span className="track-meta-label track-views">
                                                    {formatter.current.format(
                                                        result.view_count
                                                    )}{" "}
                                                    views
                                                </span>
                                                {result.like_count && (
                                                    <span className="track-meta-label track-view-count">
                                                        {formatter.current.format(
                                                            result.like_count
                                                        )}{" "}
                                                        likes
                                                    </span>
                                                )}
                                            </div>
                                            <p className="track-meta-label track-uploader">
                                                {result.uploader}
                                            </p>
                                        </div>
                                        <span className="track-meta-duration">
                                            {result.duration_string}
                                        </span>
                                    </div>
                                );
                            })}
                        </div>
                    )
                )}
            </div>
            <div className="divider" />
            <div className="player-options">
                {isTabletOrMobile ? (
                    <Menu actions={playerOptions} />
                ) : (
                    playerOptions
                )}
            </div>
        </div>
    );
};

export default Search;
