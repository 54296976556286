import React from "react";
import classNames from "classnames";

// Styles
import "./style.sass";

const ToolTip = ({ children, content, placement = "top" }) => {
    return (
        <div className={classNames("tooltip", placement)}>
            <div className="tooltip-content">{children}</div>
            <div className="tooltip-residuals">
                <div className="tooltip-arrow" />
                <div className="tooltip-inner">{content}</div>
            </div>
        </div>
    );
};

export default ToolTip;
