// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-helper .modal-helper-asset {
  width: 100%;
  height: auto;
}
.modal-helper .modal-title {
  max-width: 540px;
  color: initial !important;
  margin: 12px 0;
  margin-bottom: 24px;
}
.modal-helper .modal-title a {
  color: initial !important;
  text-decoration: underline !important;
}`, "",{"version":3,"sources":["webpack://./src/components/modals/info/style.sass"],"names":[],"mappings":"AAIE;EACE,WAAA;EACA,YAAA;AAHJ;AAKE;EACE,gBAAA;EACA,yBAAA;EACA,cAAA;EACA,mBAAA;AAHJ;AAKI;EACE,yBAAA;EACA,qCAAA;AAHN","sourcesContent":["@import \"../../../constants\"\n\n.modal-helper\n\n  .modal-helper-asset\n    width: 100%\n    height: auto\n\n  .modal-title\n    max-width: 540px\n    color: initial !important\n    margin: 12px 0\n    margin-bottom: 24px\n\n    a\n      color: initial !important\n      text-decoration: underline !important"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
