import React, {
    useState,
    useEffect,
    useMemo,
    useCallback,
    useRef,
} from "react";
import classNames from "classnames";
import * as Vibrant from "node-vibrant";
import { useMediaQuery } from "react-responsive";

// Components
import ReactPlayer from "react-player";
import Search from "../search";
import ModalInfo from "../modals/info";
import ModalDiff from "../modals/diff";
import ToolTip from "../tooltip";
import { PlaybackControls, PlayerSlider, PlayerSettings } from "../player";

// Assets
import music_note from "../../resources/music_note.svg";
import sync from "../../resources/sync_white.svg";
import sync_disabled from "../../resources/sync_disabled_white.svg";
import voice_over_on from "../../resources/record_voice_over_white.svg";
import voice_over_off from "../../resources/voice_over_off_white.svg";
import flash_on from "../../resources/flash_on_white.svg";
import flash_off from "../../resources/flash_off_white.svg";

// Styles
import "./style.sass";
import { textEllipsis } from "../../utils";

const INSTRUMENTAL_OFFSET = 5;
const NEW_LINE_OFFSET = 0.5;
const ABSOLUTE_OFFSET = 0.25;
const MAX_LINES = 3;
const MAX_TITLE_CHARACTERS = 20;

const ToggleButton = ({
    enabled,
    setEnabledToggle,
    className,
    disabled = false,
    states,
}) => {
    const state = enabled ? states.on : states.off;

    return (
        <ToolTip content={state.alt}>
            <img
                className={classNames("player-option", className, {
                    disabled: disabled,
                })}
                src={state.img}
                onClick={() => !disabled && setEnabledToggle((state) => !state)}
                alt={state.alt}
            />
        </ToolTip>
    );
};

const useToggle = (initialState) => {
    const [enabled, setEnabled] = useState(initialState);
    const toggle = useCallback(
        () => setEnabled((state) => !state),
        [setEnabled]
    );
    return [enabled, toggle];
};

const PlayerInfo = ({ meta, sample }) => {
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });
    const isReliableMatch =
        !meta.lyrics.hasOwnProperty("is_reliable_match") ||
        meta.lyrics.is_reliable_match;
    const srcURL = isReliableMatch
        ? meta.lyrics.header_image_url
        : meta.audio.thumbnails[0].url;
    return (
        <div className="player-info">
            <img
                className="track-thumbnail"
                src={srcURL}
                alt="Track thumbnail"
            />
            {isReliableMatch ? (
                <div className="track-meta">
                    <p className="track-meta-label track-title">
                        {sample.title}
                    </p>
                    <p className="track-meta-label track-artist">
                        {sample.artist}
                    </p>
                </div>
            ) : (
                <div className="track-meta">
                    <p className="track-meta-label track-title">
                        {isTabletOrMobile
                            ? textEllipsis(
                                  meta.audio.title,
                                  MAX_TITLE_CHARACTERS
                              )
                            : meta.audio.title}
                    </p>
                </div>
            )}
        </div>
    );
};

const Player = ({ demos, track }) => {
    const [syncWithLyrics, setSyncWithLyrics] = useToggle(true);
    const [playWithVocals, setPlayWithVocals] = useToggle(true);
    const [smoothTransitionEnabled, setSmoothTransitionEnabled] =
        useToggle(true);
    const [toggleInfo, setToggleInfo] = useState(false);
    const [toggleDiff, setToggleDiff] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [progress, setProgress] = useState({ playedSeconds: 0 });
    const [playerOptions, setPlayerOptions] = useState({
        playing: false,
        volume: 1,
        muted: false,
    });
    const [vibrantColor, setVibrantColor] = useState(null);
    const [currentTrack, setCurrentTrack] = useState(track);
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });
    const player = useRef(null);

    const { sample, transcript, alignment, meta } = currentTrack;

    const verses = useMemo(() => transcript.split("\n"), [transcript]);
    const linesMatched = useMemo(() => {
        if (!alignment) {
            return [];
        }

        let counter = 0,
            prev = 0;
        return verses.reduce((acc, line) => {
            const formattedLine = line
                .replace(/[^A-Za-z ']/g, " ")
                .replace(/\s+/g, " ")
                .trim();
            if (formattedLine.length === 0) {
                return acc;
            }

            const tokens = formattedLine.split(" ");
            if (counter + tokens.length - 1 >= alignment.length) {
                return acc;
            }

            const absoluteOffset = alignment[counter][0];
            const relativeOffset = absoluteOffset - prev;
            prev = alignment[counter + tokens.length - 1][1];

            const newLine = tokens.reduce((acc, token) => {
                if (token && token.length > 0) {
                    return acc.concat([alignment[counter++]]);
                }

                return acc;
            }, []);

            return acc.concat([
                {
                    tokens: newLine,
                    absoluteOffset,
                    relativeOffset,
                    totalOffset: prev,
                },
            ]);
        }, []);
    }, [verses, alignment]);

    const [offset, lines] = useMemo(() => {
        const { playedSeconds } = progress;

        if (!linesMatched) return [];

        return [
            playedSeconds,
            linesMatched
                .filter(
                    (line) => line.totalOffset > playedSeconds - NEW_LINE_OFFSET
                )
                .slice(0, MAX_LINES),
        ];
    }, [linesMatched, progress]);

    useEffect(() => {
        setToggleDiff(currentTrack.raw_output && !currentTrack.alignment);
    }, [currentTrack]);

    useEffect(() => {
        const isNew = localStorage.getItem("visited") == null;
        if (isNew) {
            localStorage.setItem("visited", "yes");
        }

        setToggleInfo(isNew);
    }, []);

    useEffect(() => {
        if (!meta) return;

        // palette.LightVibrant.hex
        Vibrant.from(meta.lyrics.header_image_thumbnail_url)
            .getPalette()
            .then((palette) => {
                setVibrantColor(palette.LightVibrant.hex);
            });
    }, [meta]);

    const renderPlayerOptionsInfo = () => (
        <div className="player-info player-options">
            <ToggleButton
                className="player-smooth-transition"
                enabled={smoothTransitionEnabled}
                setEnabledToggle={setSmoothTransitionEnabled}
                states={{
                    on: {
                        img: flash_on,
                        alt: "Disable Smooth Transition",
                    },
                    off: {
                        img: flash_off,
                        alt: "Enable Smooth Transition",
                    },
                }}
            />
            <ToggleButton
                className="player-vocals-toggle"
                enabled={playWithVocals}
                setEnabledToggle={setPlayWithVocals}
                states={{
                    on: {
                        img: voice_over_on,
                        alt: "Remove Vocals",
                    },
                    off: {
                        img: voice_over_off,
                        alt: "Enable Vocals",
                    },
                }}
            />
            <ToggleButton
                className="player-lyrics-sync"
                disabled={true}
                enabled={syncWithLyrics}
                setEnabledToggle={setSyncWithLyrics}
                states={{
                    on: {
                        img: sync,
                        alt: "Synchronize with lyrics",
                    },
                    off: {
                        img: sync_disabled,
                        alt: "Don't synchronize with lyrics",
                    },
                }}
            />
            {!isTabletOrMobile && (
                <PlayerSettings
                    volume={playerOptions.volume}
                    setVolume={(volume) =>
                        setPlayerOptions((state) => ({
                            ...state,
                            volume,
                        }))
                    }
                    muted={playerOptions.muted}
                    toggleMuted={() =>
                        setPlayerOptions((state) => ({
                            ...state,
                            muted: !state.muted,
                        }))
                    }
                />
            )}
        </div>
    );

    const bgColor = vibrantColor ?? "#111111";

    return (
        <div className="track">
            <div className="track-lyrics" style={{ backgroundColor: bgColor }}>
                <Search
                    demos={demos}
                    track={currentTrack}
                    setCurrentTrack={setCurrentTrack}
                    toggleInfo={toggleInfo}
                    isDisabled={isDisabled}
                    setIsDisabled={setIsDisabled}
                    setToggleInfo={setToggleInfo}
                    setToggleDiff={setToggleDiff}
                    toggleDiff={toggleDiff}
                />
                <ModalInfo
                    bgColor={bgColor}
                    toggleInfo={toggleInfo}
                    setToggleInfo={setToggleInfo}
                />
                <ModalDiff
                    bgColor={bgColor}
                    toggleDiff={toggleDiff}
                    setToggleDiff={setToggleDiff}
                    track={currentTrack}
                    setIsDisabled={setIsDisabled}
                    setToggleInfo={setToggleInfo}
                    setCurrentTrack={setCurrentTrack}
                />
                <div className="track-lyrics-flow">
                    {lines.map((line, lineIndex) => {
                        const fill =
                            (1.0 -
                                Math.max(
                                    (line.absoluteOffset - offset) /
                                        line.relativeOffset,
                                    0
                                )) *
                            100;
                        const style = {
                            clipPath: `polygon(0 0, 100% 0, 100% ${fill}%, 0 ${fill}%)`,
                        };

                        return (
                            <React.Fragment key={lineIndex}>
                                <div className="lyrics-verse">
                                    {line.relativeOffset >
                                        INSTRUMENTAL_OFFSET && (
                                        <div className="instrumental-part">
                                            <img
                                                className="instrumental-part-initial"
                                                src={music_note}
                                                alt="Instrumental Part"
                                            />
                                            <img
                                                className="instrumental-part-initial instrumental-part-fill"
                                                src={music_note}
                                                alt="Instrumental Part"
                                                style={style}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className="lyrics-verse">
                                    {line.tokens.map(
                                        ([start, end, token, _]) => {
                                            const key = `${start}:${end} - ${token}`;
                                            let leftOffset = 100;
                                            if (
                                                offset + ABSOLUTE_OFFSET >
                                                start
                                            ) {
                                                if (smoothTransitionEnabled) {
                                                    leftOffset =
                                                        Math.min(
                                                            Math.max(
                                                                (end - offset) /
                                                                    (end -
                                                                        start),
                                                                0
                                                            ),
                                                            1
                                                        ) * 100;
                                                } else {
                                                    leftOffset = 0;
                                                }
                                            }

                                            const style = {
                                                backgroundPosition: `${leftOffset}% 0`,
                                            };

                                            return (
                                                <span
                                                    key={key}
                                                    className={classNames(
                                                        "word",
                                                        {
                                                            animated: true,
                                                            "upcoming-frame":
                                                                lines.length -
                                                                    1 ===
                                                                lineIndex,
                                                        }
                                                    )}
                                                    style={style}
                                                >
                                                    {token}{" "}
                                                </span>
                                            );
                                        }
                                    )}
                                    <br />
                                </div>
                            </React.Fragment>
                        );
                    })}
                </div>
            </div>
            <div
                className={classNames("player", {
                    "player-floating": isTabletOrMobile,
                })}
            >
                <div
                    className={classNames("player-container", {
                        "player-floating-container": isTabletOrMobile,
                    })}
                >
                    <PlayerInfo meta={meta} sample={sample} />
                    <ReactPlayer
                        ref={player}
                        url={
                            playWithVocals
                                ? currentTrack.url
                                : currentTrack.instrumentalUrl
                        }
                        playing={playerOptions.playing}
                        onPlay={() =>
                            setPlayerOptions((state) => ({
                                ...state,
                                playing: true,
                            }))
                        }
                        onPause={() =>
                            setPlayerOptions((state) => ({
                                ...state,
                                playing: false,
                            }))
                        }
                        config={{ file: { forceAudio: true } }}
                        style={{ display: "none" }}
                        volume={playerOptions.volume}
                        onProgress={(progress) =>
                            setProgress(
                                Object.assign(
                                    {},
                                    { playedSeconds: 0 },
                                    progress
                                )
                            )
                        }
                        progressInterval={5}
                    />
                    <div className="player-main">
                        <PlaybackControls
                            playing={playerOptions.playing}
                            prevSong={() => {
                                if (progress.playedSeconds > 2) {
                                    player.current.seekTo(0);
                                } else {
                                    // Previous track
                                }
                            }}
                            nextSong={() => {}}
                            togglePlaying={() =>
                                setPlayerOptions((state) => ({
                                    ...state,
                                    playing: !state.playing,
                                }))
                            }
                        />
                        <PlayerSlider
                            isTabletOrMobile={isTabletOrMobile}
                            player={player}
                            progress={progress}
                        />
                    </div>
                    {renderPlayerOptionsInfo()}
                </div>
            </div>
        </div>
    );
};

function Main() {
    const [sample, setSample] = useState(null);
    const [demos, setDemos] = useState(null);

    useEffect(() => {
        fetch("/alignment")
            .then((res) => res.json())
            .then(setSample);
    }, []);

    useEffect(() => {
        fetch("/demos")
            .then((res) => res.json())
            .then(setDemos);
    }, []);

    return (
        <div className="main">
            {sample && <Player demos={demos} track={sample} />}
        </div>
    );
}

export default Main;
