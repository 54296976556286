// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip {
  position: relative;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
}
.tooltip:hover .tooltip-residuals {
  visibility: visible;
  opacity: 1;
}
.tooltip .tooltip-content {
  display: flex;
}
.tooltip .tooltip-residuals {
  visibility: hidden;
  position: absolute;
  top: -12px;
  left: 50%;
  transform: translate(-50%, 0);
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}
.tooltip .tooltip-residuals .tooltip-arrow {
  position: absolute;
  border-color: transparent;
  border-style: solid;
  border-width: 0.4rem 0.4rem 0 0.4rem;
  left: -0.4rem;
  border-top-color: #000;
}
.tooltip .tooltip-residuals .tooltip-inner {
  position: absolute;
  transform: translate(-50%, -100%);
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}`, "",{"version":3,"sources":["webpack://./src/components/tooltip/style.sass"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,kBAAA;EACA,gBAAA;EACA,gBAAA;EACA,iBAAA;EACA,qBAAA;EACA,iBAAA;EACA,oBAAA;EACA,sBAAA;EACA,kBAAA;EACA,oBAAA;EACA,mBAAA;EACA,gBAAA;EACA,mBAAA;EACA,qBAAA;AACF;AAEI;EACE,mBAAA;EACA,UAAA;AAAN;AAEE;EACE,aAAA;AAAJ;AAEE;EACE,kBAAA;EACA,kBAAA;EACA,UAAA;EACA,SAAA;EACA,6BAAA;EACA,UAAA;EACA,qCAAA;AAAJ;AAEI;EACE,kBAAA;EACA,yBAAA;EACA,mBAAA;EACA,oCAAA;EACA,aAAA;EACA,sBAAA;AAAN;AAEI;EACE,kBAAA;EACA,iCAAA;EACA,gBAAA;EACA,uBAAA;EACA,WAAA;EACA,kBAAA;EACA,sBAAA;EACA,sBAAA;AAAN","sourcesContent":[".tooltip\n  position: relative\n  font-style: normal\n  font-weight: 400\n  line-height: 1.5\n  text-align: start\n  text-decoration: none\n  text-shadow: none\n  text-transform: none\n  letter-spacing: normal\n  word-break: normal\n  word-spacing: normal\n  white-space: normal\n  line-break: auto\n  font-size: .875rem\n  word-wrap: break-word\n\n  &:hover\n    .tooltip-residuals\n      visibility: visible\n      opacity: 1.0\n\n  .tooltip-content\n    display: flex\n\n  .tooltip-residuals\n    visibility: hidden\n    position: absolute\n    top: -12px\n    left: 50%\n    transform: translate(-50%, 0)\n    opacity: 0\n    transition: opacity 300ms ease-in-out\n\n    .tooltip-arrow\n      position: absolute\n      border-color: transparent\n      border-style: solid\n      border-width: 0.4rem 0.4rem 0 0.4rem\n      left: -0.4rem\n      border-top-color: #000\n\n    .tooltip-inner\n      position: absolute\n      transform: translate(-50%, -100%)\n      max-width: 200px\n      padding: 0.25rem 0.5rem\n      color: #fff\n      text-align: center\n      background-color: #000\n      border-radius: 0.25rem"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
